html {
  height: 100vh;
}

html, body, #root, .App {
  display: flex;
  flex: 1;
}

html.ms-app {
  flex: 0;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.App {
  /* Prevent clipping of MS app loader */
  min-height: 400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Table-small .MuiTableCell-sizeSmall,
.Table-small .MuiInputBase-inputMarginDense,
.Table-small .MuiInputLabel-marginDense {
  font-size: 0.675rem;
}

.Table-small .MuiTableCell-sizeSmall {
  padding: 3px;
}

input.input-balance {
  margin-top: 1px;
}

/* Chrome, Safari, Edge, Opera */
input.input-balance::-webkit-outer-spin-button,
input.input-balance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-balance[type=number] {
  -moz-appearance: textfield;
}

.MuiFormControlLabel-root {
  /* Fix the label overflowing its container */
  margin-left: 0 !important;
}

.MuiFormControlLabel-labelPlacementStart {
  /* Fix the label overflowing its container */
  margin-right: 0 !important;
}

.MuiButtonBase-root.Mui-disabled:disabled {
  /* Add proper cursor on disabled buttons */
  cursor: not-allowed;
  pointer-events: unset;
}

.MuiGrid-item {
  display: inline-block;
  flex: 0;
}

.notistack-Snackbar, .notistack-MuiContent {
  min-width: 0;
}