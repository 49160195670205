/* NOTE: Making text more readable - all font-face files are one step bolder than default, except 900 */
@font-face {
  src: url(./assets/fonts/gilroy-black.ttf);
  font-family: "Gilroy";
  font-weight: 900;
}

@font-face {
  src: url(./assets/fonts/gilroy-bold.ttf);
  font-family: "Gilroy";
  font-weight: 700 800;
}

@font-face {
  src: url(./assets/fonts/gilroy-semibold.ttf);
  font-family: "Gilroy";
  font-weight: 600;
}

@font-face {
  src: url(./assets/fonts/gilroy-medium.ttf);
  font-family: "Gilroy";
  font-weight: 500;
}

@font-face {
  src: url(./assets/fonts/gilroy-regular.ttf);
  font-family: "Gilroy";
  font-weight: 400;
}

@font-face {
  src: url(./assets/fonts/gilroy-thin.ttf);
  font-family: "Gilroy";
  font-weight: 300;
}

@font-face {
  src: url(./assets/fonts/gilroy-ultralight.ttf);
  font-family: "Gilroy";
  font-weight: 100;
}

/*noinspection CssOverwrittenProperties*/
body {
  margin: 0;
  padding: 0;
  /* Override MUI popup styles */
  padding-right: 0 !important;
  font-family: "Gilroy", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;
  /* Override MUI popup styles */
  overflow-y: auto !important;
  outline: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table *,
.MuiTableCell-root {
  white-space: pre-line;
}

td.MuiTableCell-root {
  padding: 10px 8px;
  min-width: 64px;
  overflow-wrap: anywhere;
}

.header a.nav {
  text-decoration: none;
  color: white;
}

.header a.nav p {
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: white;
}

h1 {
  color: #3987cf;
  font-size: 36px;
  font-weight: 900;
}

h4 {
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 10px;
  cursor: pointer;
  color: #686868;
  font-size: 14px;
}

h4.active-border {
  border-bottom: 4px solid #3987cf;
  z-index: 11;
  margin-bottom: -3px;
}

h4:nth-child(2) {
  margin-left: 2rem;
}

h5 {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 10px;
}

.header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 10px;
  background: #3987cf;
  /* Hack for 768p */
  overflow-x: auto;
}

.ms-app .header {
  background: #1f75a8;
  height: 58px;
}

.screenLoader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* NOTE: Hardcoded header height */
  height: calc(100vh - 80px);
}

.ms-app .screenLoader {
  /* NOTE: Hardcoded MS app header height */
  height: calc(100vh - 58px);
}

.nav {
  display: flex;
  align-items: center;
  margin: 0 0.2rem;
  padding: 0 1.1rem;
}

.nav.active {
  background: #185085;
}

.nav img,
.nav svg {
  margin-right: 10px;
}

.nav-right-outer, .nav-right-inner {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.nav-right-outer > img {
  height: 22px;
  align-self: center;
}

.nav-right-inner {
  margin-left: .55rem;
}

.nav-left {
  display: flex;
  height: 100%;
}

.body_wrapper_zero {
  flex: 1;
  max-width: 100%;
  padding: 0 20px 20px;
  border-left: 1px solid #DFE4EB;
}

.body_wrapper {
  width: 98%;
  margin: 20px 0 20px 0;
}

.body_wrapper__home {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  /* NOTE: Default width doesn't cover all content */
  width: 100%;
}

.body_wrapper__two {
  display: flex;
  padding: 40px 20px;
  /* NOTE: Default width doesn't cover all content */
  width: fit-content;
}

.body_wrapper__table {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  /* NOTE: Default width doesn't cover all content */
  width: fit-content;
}

.table_head {
  color: #909090;
  background: #d7e7f5;
  display: flex;
  align-items: center;
  padding: 0 20px;
  min-width: 900px;
}

.table_head__ozon {
  background: #3987cf;
  color: white;
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  width: 240px;
}

.table_head__wildberries {
  background: #a11f94;
  color: white;
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  width: 240px;
}

.ozon,
.wildberries,
.moysklad {
  padding: 7px 15px;
  width: 117px;
  border-radius: 5px;
  color: white;
  border: none;
  margin-left: 10px;
  font-size: 12px;
  text-align: center;
}

.ozon {
  background: #3987cf;
}

.wildberries {
  background: #a11f94;
}

.moysklad {
  background: #2855af;
}

.main_input_container {
  position: relative;
  margin-right: 15px;
  width: 324px;
}

@media screen and (max-width: 1550px) {
  .main_input_container {

    width: 250px;
  }
}

@media screen and (max-width: 1440px) {
  .main_input_container {
    width: 210px;
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.main_input {
  width: 230px;
  padding: 5px 10px;
  background: none;
  border: 1px solid #c4c4c4;
  color: #9e9e9e;
  border-radius: 5px;
  margin: 0 12px 0 0;
  height: 32px;
  outline: none;
  box-sizing: border-box;
}

.filter_wrapper {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 20px 10px;
}

.filter_wrapper input {
  width: 250px;
  padding: 5px 10px;
  background: white;
  border: 1px solid #c4c4c4;
  color: #9e9e9e;
  height: 26px;
  box-sizing: border-box;
  margin: 7px 0;
  border-radius: 5px;
  outline: none;
}

::placeholder {
  color: #9e9e9e;
  opacity: 1;
}

.filter_wrapper select {
  width: 250px;
  padding: 5px 10px;
  background: white;
  border: 1px solid #c4c4c4;
  color: #9e9e9e;
  margin: 7px 0;
  border-radius: 5px;
  outline: none;
}

.body_select {
  width: 100px;
  padding: 5px;
  border: 0.5px solid #bebebe;
  border-radius: 5px;
  outline: none;
  color: #353535;
  font-weight: 300;
  background-image: linear-gradient(to bottom, #ffffff, #e8eef3);
}

.button_counter {
  min-width: 106px;
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  outline: none;
  color: #ffffff;
  font-weight: 300;
  height: 32px;
  background: #3987CF;
  margin-right: 0.8rem;
}

.button_grey {
  min-width: 100px;
  padding: 5px 8px;
  border: 0.5px solid #bebebe;
  margin: 0.4rem !important;
  border-radius: 5px;
  outline: none;
  color: #353535;
  font-weight: 300;
  background-image: linear-gradient(to bottom, #ffffff, #e8eef3);
  cursor: pointer;
}

.button_dropdown {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 100px;
  padding: 5px 8px;
  border: none;
  margin: 0.4rem 0.8rem 0.4rem 0 !important;
  border-radius: 5px;
  outline: none;
  color: #fff;
  font-weight: 300;
  background: #3987CF;
  cursor: pointer;
  height: 32px;
}

.button_dropdown svg {
  font-size: 16px;
}

.button_blue {
  cursor: pointer;
  outline: none;
  min-width: 80px;
  padding: 5px;
  border: 0.5px solid #bebebe;
  margin: 0.4rem !important;
  border-radius: 5px;
  height: 30px;
  color: #fff;
  font-weight: 300;
  background-image: linear-gradient(
      180deg,
      rgb(109 171 229),
      #3987cf
  )
}

.btn {
  cursor: pointer;
  outline: none;
  width: 106px;
  padding: 5px;
  border: 0;
  margin: 0.4rem 0.8rem 0.4rem 0 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: #ffffff;
  font-weight: 300;
  background: #3987CF;
}

.btn img {
  margin: 0 0 0 5px;
}

.btn_isactive {
  box-shadow: inset 0 1px 2px #1616167e;
}

.btn_isapplied {
  background-image: linear-gradient(to bottom, #ffffff, #cee2f3);
}

.btn-tovar {
  margin-left: 0;
  cursor: pointer;
  outline: none;
  width: 80px;
  padding: 5px;
  border: 0.5px solid #bebebe;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: #fff;
  font-weight: 300;
  background-image: linear-gradient(
      180deg,
      rgb(109 171 229),
      #3987cf
  )
}

.btn_null {
  padding: 0;
  width: 50px;
  height: 30px;
  border: 0.5px solid #bebebe;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #353535;
  font-weight: 300;
  background-image: linear-gradient(to bottom, #ffffff, #e8eef3);
}

.search_btn {
  width: 250px;
  height: 50px;
  background-image: linear-gradient(45deg, #025383, #3987cf);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-right: 2rem;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.search_btn:hover {
  opacity: 0.7;
}

.btn_settings {
  display: flex;
  justify-content: center;
  width: 30px;
  padding: 2px 4px;
  background-image: linear-gradient(to bottom, #ffffff, #e8eef3);
  border: 0.5px solid #bebebe;
  margin-left: 0.4rem;
  border-radius: 5px;
}

.button_grey:disabled,
.button_dropdown:disabled,
.button_blue:disabled,
.btn:disabled,
.btn-tovar:disabled,
.btn_isactive:disabled,
.btn_null:disabled,
.search_btn:disabled,
.btn_settings:disabled {
  background: #9f9f9f;
  cursor: not-allowed;
}

.table-border {
  border: 2px solid #d7e7f5;
  text-align: center;
}

.MuiTableCell-body {
  font-size: 10px !important;
  line-height: 12px !important;
}

.modal {
  position: absolute;
  width: 400px;
  padding: 0 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiSvgIcon-root.MuiTableSortLabel-icon {
  color: #3987cf;
}

.sidebar_container {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.sidebar_logo {
  padding: 30px 30px 0 40px;
  border-bottom: 1px solid;
}

.sticky_sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  padding: 30px 0 0 0;
  min-height: 30vh;
  width: 250px;
}

.app_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main_content {
  display: flex;
  flex: 1;
}

.nav_account {
  border: #9f9f9f 1px;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 80%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #3987CF;
  background: #F0F2F5;
  max-width: 250px;
}

.nav_account_selected, .default-border {
  box-shadow: 0 3px 12px 0 #1F364D26;
  background: #ffffff;
  border-radius: 10px;
}

.nav_account_name {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.nav_account_selected .nav_account_name {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}

.nav_account_name p {
  margin: 10px 0 10px 10px;
  padding: 0;
  word-wrap: break-word;
  white-space: normal;
  min-width: 110px;
  max-width: 110px;
}

.nav_account_actions {
  display: flex;
  justify-content: flex-start;
  color: #727272;
  padding: 10px;
}

.nav_account_actions ul {
  width: 100%;
  border-top: 1px solid #F4F4F4;
  box-sizing: border-box;
  padding-top: 10px;
}

.nav_account_link {
  display: flex;
  background: #ffffff;
  text-decoration: none;
}

.nav_account_link_selected p {
  color: #3987CF !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
}

.nav_account_actions li {
  display: flex;
  padding: 6px;
}

.nav_account_actions p {
  margin: 0 0 0 5px;
  color: #727272;
}

.nav_account_actions p :hover {
  margin: 0 0 0 5px;
  color: #aaa;
}

.nav_account_add, .default-button {
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: space-around;
  /* TODO: Why default margins? Gets in the way of any flex alignment. */
  margin-bottom: 10px;
  margin-top: 20px;
  background: #3987CF;
  border: #9f9f9f 1px;
  border-radius: 10px;
  cursor: pointer;
  color: #FFF;
  height: 40px;
  align-items: center;
}

.nav_account_add:hover {
  cursor: pointer;
}

.nav_account_add p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #fff;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 13px;
}

.slider.round:before {
  border-radius: 50%;
}

.main_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #DFE4EB;
}

.logo_container {
  width: 200px;
  margin: 0 20px;
}

.logo {
  height: 50px;
}

.navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0;
  margin: 0 20px;
}

.nav_link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding: 0 25px;
}

.nav_link_underlined {
  display: inline-block;
  color: #3987CF;
  font-weight: bold;
  position: relative;
}

.nav_link_underlined::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px; /* Настройте положение под линией текста */
  height: 2px; /* Толщина подчеркивания */
  background-color: #3987CF; /* Цвет подчеркивания */
  display: block;
  width: 100%;
}

.user-account {
  height: 50px;
  display: flex;
  align-items: center;
}

.user-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.border_left {
  border-left: 1px solid #DFE4EB;
}

.MuiAppBar-root {
  border: none;
  box-shadow: none !important;
}

.MuiTypography-body2 {
  font-size: 10px !important;
  line-height: 12px !important;
}

.reactive_tabs span {
  font-size: 12px;
  line-height: 14.4px;
}

.MuiTableCell-head {
  line-height: 12px !important;
}

.table_head_row th {
  color: #4A7DAC;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 12px;
}

.table_head_row th span {
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 12px;
}

.selected_row > span, .selected_row th > span, .selected_row td, .selected_row th span div {
  color: #3987CF !important;
  font-weight: 600;
}

.selected_row {
  border: 2px solid #FFFFFF;

}

.orders_search_field {
  color: #3987CF !important;
}